<template>
  <div class="help-category-page-detail">
    <BackButton
      label="Retour"
      @click="onBackBtnClick"
    />
    <div
      class="splash"
      :style="splashStyle" >
      <div class="splash-title">{{ article.title }}</div>
    </div>
    <HCard class="page-content">
      <div
        class="introduction">
        <MarkdownContent :value="article.content" v-if="article.content" />
        </div>
      <div class="partners">
        <HCard
          type="free"
          class="partner-card"
          v-for="(partner, partnerIndex) in partners"
          :key="partnerIndex"
        >
          <div class="banner">
            <div class="avatar">
              <span class="border"></span>
              <img class="photo" :src="partner.logoUrl" border="0" />
            </div>
          </div>
          <div class="content">
            <div class="content-title">{{ partner.name }}</div>
            <div class="content-description">
            <MarkdownContent :value="partner.description" v-if="partner.description" /></div>
            <div class="actions">
              <HBtn
                class="discover-btn"
                data-cy="discover-btn"
                :href="partner.website"
                target="_blank"
                >Découvrir leur site</HBtn>
            </div>
          </div>
        </HCard>
      </div>
    </HCard>
  </div>
</template>

<script>
import _ from 'lodash'
import MarkdownContent from '@/components/MarkdownContent.vue'
import Routes from '@/constants/routes'
import Http from '@/http'

import ToastMixin from '@/mixins/toasts'
import residentMixin from '@/mixins/residentMixin'

import {
  HBtn,
  HCard
} from '@happytal/bo-ui-library'

import BackButton from '@/components/BackButton.vue'

export default {
  name: 'HelpCategoryDetail',
  mixins: [
    ToastMixin,
    residentMixin
  ],
  components: {
    HBtn,
    HCard,
    BackButton,
    MarkdownContent
  },
  props: [
    'article_id'
  ],
  data () {
    return {
      article: {},
      partners: []
    }
  },
  computed: {
    splashStyle () {
      let style = `background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`
      const imageUrl = _.get(this.article, 'bannerUrl', null)
      if (imageUrl) {
        style += `, url(${imageUrl});`
      }
      return style
    }
  },
  methods: {
    onBackBtnClick (e) {
      this.$router.push({
        name: this.$_residentId ? Routes.ResidentHelpCategory : Routes.HelpCategory
      })
    },
    updateArticle (article) {
      if (!article) {
        return
      }
      let finalArticle = {
        id: article.id,
        title: article.title,
        bannerUrl: _.get(article, 'banner.url', ''),
        content: _.get(article, 'content', '')
      }
      if (article.title) {
        document.title = article.title
      }
      if (article.partners) {
        this.partners = _.map(article.partners, (partner) => {
          return {
            id: partner.id,
            name: partner.name,
            phone: partner.phone,
            website: partner.website,
            description: partner.description,
            logoUrl: _.get(partner, 'logo.url', '')
          }
        })
      }
      this.article = finalArticle
    },
    getArticle () {
      Http.apis.strapi.get(`/articles/${this.article_id}`)
      .then((response) => {
        this.updateArticle(_.get(response, 'data', null))
      })
      .catch((error) => {
        this.$_showErrorToast(`Une erreur s'est produite (${error}).`)
      })
    }
  },
  mounted () {
    this.getArticle()
  }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.help-category-page-detail {

  .splash {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 0px 35px 0px 35px;
    border-radius: 6px 6px 6px 6px;
    background-repeat: no-repeat;
    background-size: cover;

    .splash-title {
      max-width: 700px;
      filter: brightness(100%);
      font-size: 30px;
      font-weight: 400;
      text-align: center;
      color: white;

      @media (max-width: map-get($breakpoints, sm)) {
          font-size: 25px;
      }
    }
  }

  .page-content {
    margin: 30px 0px 20px 0px;

    .introduction {
      padding: 20px 20px 20px 20px;
      font-size: 18px;
      font-weight: 400;
    }

    .partners {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      justify-content: center;

      .partner-card {
        display: flex;
        flex-direction: column;
        width: 450px;
        margin: 38px 19px 65px 19px;
        padding: 0;

        .banner {
          position: relative;
          background: #EFEFEF;
          border-radius: 6px 6px 0px 0px;

          .splash {
            height: 168px;
            overflow: hidden;
            border-radius: 6px 6px 0px 0px;

            .background {
              width: 100%;
              border-radius: 6px 6px 0px 0px;
            }
          }
          .avatar {
            position: absolute;
            left: 24px;
            bottom: -43px;
            width: 85px;
            height: 85px;
            background: white;
            border: 2px solid #C4C4C4;
            border-radius: 43px;
            white-space: nowrap;
            text-align: center;
            overflow: hidden;

            .border {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }
            .photo {
              vertical-align: middle;
              max-height: 80px;
              max-width: 80px;
            }
          }
        }
        .content {
          flex-grow: 1;
          position: relative;

          .content-title {
            padding: 50px 0px 20px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
            text-align: center;
            color: black;
          }
          .content-description {
            padding: 0px 30px 100px 30px;
            font-style: normal;
            font-size: 16px;
            line-height: 23px;
            // text-align: justify;
            color: black;
          }
          .actions {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 100px;
            text-align: center;
            padding: 30px 0px 30px 0px;

            .discover-btn {

            }
          }
        }
      }
    }
  }
}
</style>
